
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    label: String,
    iconName: String,
    condition: Function,
    action: Function
  }
})
export default class MenuButton extends Vue {}
