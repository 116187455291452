
import { Options, Vue } from 'vue-class-component';

@Options({
  props: ["icon_name", "dropdown_title", "vd"],
  emits: [
    "hide-all"
  ],
  data() {
    return {
      shown: false,
      clicked: false
    }
  },
  watch: {
    vd() {
      this.shown = this.clicked;
      this.clicked = false;
      // this.$refs.button.blur();
    }
  },
  mounted() {
    document.getElementById("glCanvas")?.addEventListener("mousedown", () => {
      this.shown = false;
    })
  },
  methods: {
    onClick() {
      this.$emit('hide-all');
      if (!this.shown) {
        this.clicked = true;
      }
      // this.$refs.button.blur();
    }
  }
})
export default class Dropdown extends Vue {}
