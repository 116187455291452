
import { Options, Vue } from 'vue-class-component';

@Options({
  data() {
    return {
      anim: ""
    }
  }
})
export default class MenuButton extends Vue {}
