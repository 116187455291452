
import { Options, Vue } from 'vue-class-component';
import Automata from './components/Automata.vue';
import Dropdown from './components/Dropdown.vue';

@Options({
  components: {
    Automata,
    Dropdown
  },
  data() {
    return {
    }
  },
  methods: {
  }
})
export default class App extends Vue {}
