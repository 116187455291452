
import { Options, Vue } from 'vue-class-component';
import { Sim, randomizeDataBuffer, RuleSpec } from '../sim'
import Dropdown from './Dropdown.vue';
import MenuButton from './MenuButton.vue';
import UIButton from './UIButton.vue';
import UIButtonToggle from './UIButtonToggle.vue';

const shaders = {
  "colormap": require('../shaders/colormap.glsl').default,
  "drawing": require('../shaders/drawing.glsl').default,
  "simulate": require('../shaders/simulate.glsl').default,
  "vertex": require('../shaders/vertex.glsl').default
};

// Process the presets stored inside rules.txt
const presetsRaw: string = require('../rules.txt').default;
const presets: Record<string, string> = {};
for (const line of presetsRaw.split("\n")) {
  const split = line.split(":: ");
  presets[split[0].trim()] = split[1];
}

@Options({
  components: {
    "dropdown": Dropdown,
    "menu-button": MenuButton,
    "ui-button": UIButton,
    "ui-button-toggle": UIButtonToggle
  },
  props: {
  },
  emits: [
    "hide-menu"
  ],
  data() {
    return {
      simulator: null,
      frameRate: "",
      presetsShow: false,
      hideall: true,
      importDialogue: false
    }
  },
  watch: {
    'simulator.fps': function() {
      this.frameRate = `${this.simulator.frames} frames/sec per ${this.simulator.steps} steps/sec`;
      this.simulator.frames = 0;
      this.simulator.steps = 0;
    }
  },
  methods: {
    blah() {
      console.debug("blah");
    },
    fillRandom() {
      randomizeDataBuffer(this.simulator.states);
      this.simulator.resetSim();
    },
    importRule() {
      const inpString = this.$refs["input-area"].value as string;
      let input: string | RuleSpec = inpString;
      if (inpString[0] == "{") {
        input = JSON.parse(inpString) as RuleSpec;
      }
      let success = false;
      try {
        success = this.simulator.importRule(input);
      } catch (error) {
        success = false;
      }
      if (success) {
        this.importDialogue = false;
        this.hideall = true;
        this.simulator.preset = "(imported)";
      } else {
        this.$refs["import-button"].anim = "button-error";
      }
    }
  },
  mounted() {
    this.$refs["glCanvas"].addEventListener("contextmenu", (e: Event) => e.preventDefault());
    this.simulator = new Sim(this.$refs["glCanvas"] as HTMLCanvasElement, presets, shaders);
    this.simulator.resize();
    window.addEventListener("resize", this.simulator.resize.bind(this.simulator));
    window.addEventListener("mousemove", this.simulator.mouseHandler.bind(this.simulator));
    this.$refs["glCanvas"].addEventListener("mousedown", this.simulator.clickOn.bind(this.simulator));
    this.$refs["glCanvas"].addEventListener("mouseup", this.simulator.clickOff.bind(this.simulator));
    this.$refs["glCanvas"].addEventListener("wheel", this.simulator.onScrollWheel.bind(this.simulator));
    window.addEventListener("keydown", this.simulator.onKey.bind(this.simulator));
    window.addEventListener("keyup", this.simulator.onKeyUp.bind(this.simulator));
    this.simulator.animateScene();
  }
})
export default class Automata extends Vue {}
