
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    label: String,
    iconNameOn: String,
    iconNameOff: String,
    condition: Function,
    action: Function
  }
})
export default class MenuButton extends Vue {}
